import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateDiffusionList } from '../../../data/dataEM';
import useEM from '../../../hooks/useEM';
import useNumbering from '../../../hooks/useNumbering';
import { useSwal } from '../../../hooks/useSwal';
import { routesEM, routesWide } from '../../../navigation/routes';
import { array } from 'prop-types';

export const DiffusionListForm = ({ crudType }) => {
  const { createEMListExtension, updateEMListExtension, EMform, EMFormListExtensions, getEMListExtension, arrayEM } = useEM();
  const { arrayNumberingExtensionsHook, getExtensions } = useNumbering();
  const { typeSwal } = useSwal();

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateDiffusionList;

  useEffect(() => {
    getExtensions();
    EMform({ action: 'clearForm' }, { form: 'diffusionList' });

    if (crudType === 'edit') {
      const actionId = {
        action: 'id',
        value: location.state.id,
      };

      EMform(actionId, { form: 'diffusionList', element: 'id' });
      const actionName = { action: 'input', value: location.state.title };
      EMform(actionName, { form: 'diffusionList', element: 'name' });

      location.state.arrayExtensions.forEach((item) => {
        EMform(
          {
            action: 'select-option',
            option: {
              label: item.Extension.extension,
              value: String(item.Extension.id),
            },
          },
          {
            form: 'diffusionList',
            element: 'listExtensions',
          }
        );
      });
    }
    setLoadingCounter(0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayNumberingExtensionsHook === 'object' && arrayNumberingExtensionsHook.length > 0) {
        data.steps.map((item) => {
          if (item.input[0].cardType === 'multiselect' || item.input[0].cardType === 'select') {
            item.input[0].options = [];
          }
          const result =
            item.input[0].cardType === 'multiselect'
              ? arrayNumberingExtensionsHook.map((item2) => {
                  if (item2.extension === null) {
                    item2.extension = 'not defined';
                  }
                  const extension = {
                    // name: item2.name,
                    label: item2.extension,
                    value: item2?.id?.toString(),
                  };
                  const found = item.input[0].options.some((e) => e.value === extension.value);
                  if (!found && extension.value !== undefined) {
                    item.input[0].options.push(extension);
                  }
                  return item;
                })
              : null;
          return result;
        });

        if (crudType === 'edit' && EMFormListExtensions.id !== location.state.id) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = EMFormListExtensions.listName;
                  break;
                case 'listExtensions':
                  item2.defaultValues = [];
                  // item2.defaultValues.push(
                  location.state.arrayExtensions.filter((item3) => {
                    // if (EMFormListExtensions.extensions.map((item4) => item4.value.toString()).includes(item3.value)) {
                    // if (!item2.defaultValues.map((item5) => item5.value === item3.value)) {
                    return item2.defaultValues.push({ id: item3.id, value: item3.Extension.name, label: item3.Extension.extension });
                    // }
                    // }
                  });
                  return false;
                  // );
                  //   const result = location.state.arrayExtensions.map((item4) => {
                  // return item3.moduleEmListId === location.state.id ? item2.defaultValues.push(item3.id) : null;
                  // })
                  // );

                  //  item2.options.filter((item3) => {
                  //    if (EMFormListExtensions.extensions.map((item4) => item4.value.toString()).includes(item3.value)) {
                  //      if (!item2.defaultValues.map((item5) => item5.value).includes(item3.value)) {
                  //        return item2.defaultValues.push(item3);
                  //      }
                  //    }
                  //    return false;
                  //  });

                  // item2.options.filter((item3) => {
                  //   const result = location.state.arrayExtensions.map((item4) => {
                  //     return item4.moduleEmListId.toString() === item3.value ? item3 : null;
                  //   });

                  //   if (result) {
                  //     if (Array.isArray(item2.defaultValues && item2.defaultValues.length > 0 && !item2.defaultValues.map((item5) => item5.value).includes(item3.value))) {
                  //       return item2.defaultValues.push(item5);
                  //     }
                  //   }
                  //   return false;
                  // });
                  break;
                default:
                  break;
              }

              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [loadingCounter]);

  const onSubmit = async () => {
    setDataSave(true);
    try {
      if (crudType === 'create') {
        typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', createEMListExtension);
      } else if (crudType === 'edit') {
        typeSwal('createLoader', '', EMform, getEMListExtension, `/${routesWide.EM}/${routesEM.diffusionLists}`, 'diffusionList', updateEMListExtension);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={EMform} dataSave={dataSave} initialData={EMFormListExtensions} crudType={crudType} arrScreen={arrayEM} />}</>
  );
};
