import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CardDropDrown } from '../../../../components/CardDropDown/CardDropDrown';
import LoaderSpinner from '../../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../../components/NavButtons/NavButtons';
import { dataScreenSettings, menusTitleAndSubtitle } from '../../../../data/dataSettings';
import useSettings from '../../../../hooks/useSettings';
import { EditPermissionsWrapper } from './EditPermissions.style';

export const EditPermissions = () => {
  const { privileges, getPrivileges, postPrivileges, getMenusSubmenus } = useSettings();
  const { edit } = dataScreenSettings;

  const navigate = useNavigate();
  const location = useLocation();
  const dataRole = location.state;

  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getPrivileges();
    getMenusSubmenus();
  }, [getPrivileges, getMenusSubmenus]);

  let submenusToRole = privileges.filter((item) => item.rolesId === dataRole.id);
  let submenusId = submenusToRole.map((item) => item.submenusId);

  useEffect(() => {
    setTimeout(() => {
      if (privileges.length !== 0 && submenusToRole.length !== 0) {
        setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
  }, [loadingCounter]);

  const handleCloseSettings = () => {
    //setCurrentActiveMenus(privileges);
    navigate('/settings/permissions', {});
  };

  const toggleSwitch = (data, isOn, setIsOn) => {
    setIsOn(!isOn);
    const permission = {
      submenuId: data.id,
      active: !isOn,
      role: dataRole.id,
      permissions: '',
    };
    postPrivileges(permission);
  };

  return (
    <EditPermissionsWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            return: {
              position: 'left',
              function: handleCloseSettings,
            },
          }}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <div className="permission">
          <div className="permission__title">
            <img src={dataRole.iconTitle} alt={dataRole.title} />
            <h4>
              {edit.title} {dataRole.title}
            </h4>
          </div>

          <div className="permission__contain-cards">
            {menusTitleAndSubtitle.map((item) => (
              <div className="permission__card" key={item.id}>
                <CardDropDrown item={item} toggleSwitch={toggleSwitch} submenusId={submenusId} selectedId={selectedId} setSelectedId={setSelectedId} layout={item.id} />
              </div>
            ))}
          </div>
        </div>
      )}
    </EditPermissionsWrapper>
  );
};
