import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useEM from '../../../hooks/useEM';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { ToggleSwitch } from '../../../components/ToggleSwich/ToggleSwitch';
import { colors } from '../../../theme/colors';
import { routesEM, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { dataScreenAlerts } from '../../../data/dataEM';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import useApplications from '../../../hooks/useApplications';

import { AlertsWrapper } from './AlertsScreen.style';

export const AlertsScreens = () => {
  const { arrayEMAlerts, getEMAlerts, changeEMAlertsCallAndRecordHook, deleteEMAlerts, uploadFileAlerts, loading } = useEM();
  const { audios } = useApplications();
  const { typeSwal } = useSwal();
  const { changeView, isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound, icon } = dataScreenAlerts;
  const [, setCallAndRecord] = useState({});

  const tableInstance = useRef(null);

  const filesMP3 = audios.audios;

  function foundFile(filename) {
    let file;
    if (filesMP3?.find((file) => file.includes(filename))) {
      file = filename;
    }
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_URL.replace('api', 'uploads') + '/mp3/' + file;
    } else {
      return '/uploads/mp3/' + filename;
    }
  }

  let navigate = useNavigate();

  useEffect(() => {
    getEMAlerts();
  }, []);

  const toggleSwitch = (data, isOn, setIsOn) => {
    typeSwal('modify').then(async (result) => {
      if (result.isConfirmed) {
        let callAndRecordVariable = {
          idAlert: data.id,
          callAndRecord: !isOn,
        };
        changeEMAlertsCallAndRecordHook(callAndRecordVariable);

        setIsOn(!isOn);

        setCallAndRecord((prevState) => ({
          ...prevState,
          [data]: { idSubmenu: data, isActive: !isOn },
        }));

        setTimeout(() => {
          window.location.reload();
        }, 600);
      }
    });
  };



  // const DATA2 = arrayLocutionsHook.map(({ filename, description, createdAt, id, companyId, Company }) => ({
  //   filename,
  //   description,
  //   createdAt,
  //   id,
  //   companyId,
  //   group: Company?.name,
  //   filepath: foundFile(filename),
  // }));


  // const dataExport = arrayLocutionsHook.map(({ filename, description, Company }) => ({
  //   Identificación: filename,
  //   Descripción: description,
  //   Grupo: Company?.name,
  // }));

  const COLUMNS = [
    {
      Header: 'Código alerta',
      accessor: 'keyCall',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => <p style={{ textAlign: 'left' }}>{row.values.name}</p>,
    },
    {
      Header: 'Lista de difusión',
      accessor: 'listDiffusionName',
    },
    {
      Header: 'Lista de difusión secundaria',
      accessor: 'secondaryListDiffusionName',
      disableSortBy: true, // Esto desactiva la capacidad de ordenar esta columna
    },
    {
      Header: 'Código de confirmación',
      accessor: 'confirmationNumber',
    },
    {
      Header: 'Mensaje Alerta',
      accessor: 'description',
      Cell: ({ row }) => (

        
        <p>
          {row.values.callAndRecord.toggle === '0' || row.values.callAndRecord.toggle === 0 ? (
            <div>


              <audio style={{ width: '210px', height: '35px' }} src={row.original.filepath} controls />
            </div>
          ) : null}
        </p>
      ),
    },
    {
      Header: 'Llamar y grabar',
      accessor: 'callAndRecord',
      disableSortBy: true,
      Cell: ({ row }) => <ToggleSwitch version={true} isActive={row.values.callAndRecord.toggle} color={colors.primaryColor} data={row.values.callAndRecord} toggleSwitch={toggleSwitch} />,
    },
  ];

  const newArray = arrayEMAlerts?.map((item) => {
    return {
      keyCall: item.keyCall,
      description: item.Soundprompt?.description,
      name: item.name,
      title: item.title,
      // filename: foundFile(item?.Soundprompt?.filename) ,
      filename: item.callAndRecord === '0' || item.callAndRecord === 0 ? item.Soundprompt?.filename : 'Llamar y grabar activado',
      callAndRecord: { toggle: item.callAndRecord, id: item.id },
      id: item.id,
      soundPromptId: item.Soundprompt?.id,
      listDiffusionName: item.principalList?.name,
      listDiffusionId: item.principalList?.id,
      secondaryListDiffusionId: item.secondaryList?.id,
      secondaryListDiffusionName: item.secondaryList?.name,
      confirmationNumber: item.confirmationNumber,
    };
  });

  const DATA = arrayEMAlerts?.map(({ keyCall, name, title, Soundprompt, callAndRecord, id, externo, principalList, secondaryList, confirmationNumber }) => ({
    keyCall,
    name,
    description: Soundprompt?.description,
    title,
    filename: Soundprompt?.filename,
    filepath: foundFile(Soundprompt?.filename),
    callAndRecord: { toggle: callAndRecord, id: id },
    externo: { toggle: externo, id: id },
    id,
    soundPromptId: Soundprompt?.id,
    listDiffusionName: principalList?.name,
    listDiffusionId: principalList?.id,
    confirmationNumber,
    secondaryListDiffusionId: secondaryList?.id,
    secondaryListDiffusionName: secondaryList?.name,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(
        ({
          keyCall,
          name,
          description,
          title,
          filename,
          callAndRecord,
          id,
          soundPromptId,
          listDiffusionName,
          listDiffusionId,
          confirmationNumber,
          secondaryListDiffusionId,
          secondaryListDiffusionName,
        }) => ({
          title: name,
          subtitle: keyCall,
          text: listDiffusionName,
          icon: icon.code,
          icon2: icon.list,
          icon3: icon.volume,
          text2: filename,
          isActive: callAndRecord?.toggle,
          keyCall,
          name,
          description,
          filename,
          callAndRecord,
          id,
          soundPromptId,
          listDiffusionName,
          listDiffusionId,
          confirmationNumber,
          secondaryListDiffusionId,
          secondaryListDiffusionName,
        })
      );
    } else {
      return DATA.map(({ keyCall, description, name, title, filename, callAndRecord, id, soundPromptId, listDiffusionName, listDiffusionId, confirmationNumber }) => ({
        title: name,
        subtitle: keyCall,
        text: listDiffusionName,
        icon: icon.code,
        icon2: icon.list,
        icon3: icon.volume,
        text2: filename,
        isActive: callAndRecord?.toggle,
        keyCall,
        name,
        description,
        filename,
        callAndRecord,
        id,
        soundPromptId,
        listDiffusionName,
        listDiffusionId,
        confirmationNumber,
      }));
    }
  };

  const dataToCards = dataCard();

  const dataExport = newArray.map(({ keyCall, name, filename, callAndRecord, listDiffusionName, secondaryListDiffusionName }) => ({
    'Código alerta': keyCall,
    Nombre: name,
    Locución: filename,
    'Llamar y grabar': callAndRecord.toggle,
    'Lista de difusión': listDiffusionName,
    'Lista de difusion secundaria': secondaryListDiffusionName,
  }));

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.EM}/${routesEM.createAlerts}`, {});
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.EM}/${routesEM.updateAlerts}`, {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteEMAlerts(item.original);
          } else {
            response = await deleteEMAlerts(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getEMAlerts();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileAlerts(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getEMAlerts();
      }
    } catch (err) {
      return err;
    }
  };

  const template = [
    {
      'Codigo Alerta': '',
      Nombre: '',
      'Código de confirmacion': '',
      'Lista_de_difusión *(id)': '',
      'Lista de difusión secundaria *(id)': '',
      'Locución *(id)': '',
      'Llamar y grabar': '0 o 1',
    },
  ];

  return (
    <AlertsWrapper className="screen__inside-size">
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            changeView: {
              position: 'right',
              function: changeView,
              isCardView: isCardView,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayEMAlerts[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          {' '}
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
          />
        </div>
      )}
    </AlertsWrapper>
  );
};
