import click from '../../assets/images/notFound/click.svg';
// Forms Icons
import textInput from '../../assets/images/forms/textInput.svg';
import extensionIcon from '../../assets/images/forms/affiliate.svg';
import speakerphone from '../../assets/images/forms/speakerphone.svg';
import phone from '../../assets/images/forms/phone.svg';
import alarm from '../../assets/images/forms/alarm.svg';
import stethoscope from '../../assets/images/forms/stethoscope.svg';
import phoneOutgoing from '../../assets/images/forms/phone-outgoing.svg';
import volume from '../../assets/images/forms/volume.svg';
//Card Icons
import phoneCard from '../../assets/images/numbering/phoneCard.svg';
import home from '../../assets/images/numbering/home.svg';
import hierarchy from '../../assets/images/numbering/hierarchy.svg';

import { IconListCheck, IconPhone } from '@tabler/icons-react';

import { routesNumbering } from '../../navigation/routes';
import { typeRegex } from '../dataRegex';

export const menuNumbering = [
  {
    text: 'Listado de numeración',
    path: routesNumbering.numberingList,
    icon: IconListCheck,
    restricted: 'numberingList',
    id: 1,
    content: 'Listado de numeración',
  },
  {
    text: 'Extensioness',
    path: routesNumbering.extensions,
    icon: IconPhone,
    restricted: 'extensions',
    id: 2,
    content: 'Extensiones',
  },
];

export const formCreateNumberingList = {
  title: 'Configuración de un nuevo número',
  titleEdit: 'Edita el número',
  textButton: 'Añadir numeración',
  steps: [
    {
      number: '1',
      title: 'Nombre y Número',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          cardType: 'id',
          ref: { form: 'numberingList', element: 'ddiId' },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Nombre',
          placeholder: 'Nombre',
          ref: { form: 'numberingList', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: phone,
          label: 'Número',
          placeholder: 'Número',
          ref: { form: 'numberingList', element: 'ddi', unique: true },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Solo números min 3 max 10',
            regex: typeRegex.inputNumber,
            errorMessageUnique: '* Ya hay un listado de numeración con este número',
          },
        },
        {
          layoutId: 1,
          cardType: 'select',
          label: 'Sede',
          icon: stethoscope,
          placeholder: 'Sede',

          ref: { form: 'numberingList', element: 'companyId', type: 'dynamic' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Sede obligatoria',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'select',
          label: 'Departamento',
          placeholder: 'Departamento',
          icon: stethoscope,
          ref: { form: 'numberingList', element: 'department', type: 'associatedData' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Departamento',
            validation: true,
            errorMessage: '* Departamento obligatorio',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Configuración de la numeración',
      id: 2,
      lastStep: true,
      input: [
        {
          cardType: 'multiFormPages',
          ref: { element: 'multiFormPages' },
          pages: [
            {
              id: 1,
              text: 'Calendario 1',
              dataPage: [],
            },
            { text: '+' },
          ],
          dataRef: [
            {
              title: 'Calendario',
              subtitle: 'Selecciona el calendario que desees configurar.',
              ref: ['calendar'],
              id: 1,
              input: [
                {
                  label: 'Calendario',
                  placeholder: 'Calendario',
                  icon: stethoscope,
                  ref: { form: 'numberingList', element: 'calendar' },
                  options: [],
                  defaultValues: {},
                  required: {
                    text: '* Calendario',
                    validation: true,
                    errorMessage: '* Calendario obligatorio',
                    regex: typeRegex.inputText,
                  },
                },
              ],
            },
            {
              title: 'Horarios',
              subtitle: 'Asigna uno o varios horarios al calendario previamente seleccionado y  selecciona una locución y un destino en abierto a cada horario.',
              ref: ['schedule', 'soundPrompt', 'destination'],
              id: 2,
              destinationOpen: [
                {
                  id: 1,
                  input: [
                    {
                      idRef: 1,
                      cardType: 'select',
                      label: 'Horario',
                      placeholder: 'Horario',
                      icon: stethoscope,
                      ref: { form: 'numberingList', element: 'schedule' },
                      options: [],
                      defaultValues: {},
                      required: {
                        text: '* Horario',
                        validation: true,
                        errorMessage: '* Horario obligatorio',
                        regex: typeRegex.inputText,
                      },
                    },
                    {
                      idRef: 1,
                      cardType: 'select',
                      label: 'Locución abierto',
                      placeholder: 'Locución abierto',
                      icon: stethoscope,
                      ref: { form: 'numberingList', element: 'soundPrompt' },
                      options: [],
                      defaultValues: {},
                      required: {
                        text: 'Locución abierto',
                        validation: false,
                      },
                    },
                    {
                      idRef: 1,
                      cardType: 'select',
                      label: 'Destino abierto',
                      placeholder: 'Destino abierto',
                      icon: stethoscope,
                      ref: { form: 'numberingList', element: 'destination' },
                      options: [],
                      defaultValues: {},
                      required: {
                        text: '* Destino abierto',
                        validation: true,
                        errorMessage: '* Destiono obligatorio',
                        regex: typeRegex.inputText,
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: 'Locución y destino en cerrado',
              subtitle: 'Configura la locución y el destino en cerrado',
              ref: ['soundPromptClose', 'destinationClose'],
              id: 3,
              input: [
                {
                  cardType: 'select',
                  label: 'Locución en cerrado',
                  placeholder: 'Locución en cerrado',
                  icon: stethoscope,
                  ref: { form: 'numberingList', element: 'soundPromptClose' },
                  options: [],
                  defaultValues: {},
                  required: {
                    text: 'Locución en cerrado',
                    validation: false,
                  },
                },
                {
                  cardType: 'select',
                  label: 'Destino en cerradp',
                  placeholder: 'Destino en cerrado',
                  icon: stethoscope,
                  ref: { form: 'numberingList', element: 'destinationClose' },
                  options: [],
                  defaultValues: {},
                  required: {
                    text: '* Destino en cerrado',
                    validation: true,
                    errorMessage: '* Destiono obligatorio',
                    regex: typeRegex.inputText,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const formCreateNumberingExtensions = {
  title: 'Crear nueva extensión',
  titleEdit: 'Edita la extensión',
  textButton: 'Crear Extensión',

  steps: [
    {
      number: '1',
      title: 'Nombre, Número y Contraseña SIP',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,

          ref: { form: 'extension', element: 'name', unique: true },
          defaultValues: null,
          required: {
            validation: true,
            text: '* Nombre',
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya hay una extension con este nombre',
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número',
          placeholder: 'Extensión',
          icon: phone,
          ref: { form: 'extension', element: 'extension', unique: true },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Solo números min 3 max 10',
            regex: typeRegex.inputNumber,
            errorMessageUnique: '* Ya hay una extension con este número',
          },
        },
        {
          layoutId: 1,
          cardType: 'inputRandom',
          label: 'Contraseña SIP',
          placeholder: 'Contraseña SIP',
          ref: { form: 'extension', element: 'password' },
          defaultValues: null,
          icon: phone,
          required: {
            text: '* Contraseña SIP',
            validation: true,
            errorMessage: '* 8 caracters mínimo 1 letra 1 número',
            regex: typeRegex.inputPassword,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Tipo de extensión, Número de desvío, No contestar, Buzón',
      id: 2,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 2,
          cardType: 'select',
          label: 'Extensión',
          icon: extensionIcon,
          placeholder: 'Tipo de extensión',
          ref: { form: 'extension', element: 'typeExtension' },
          options: [
            { label: 'sip', value: 'sip' },
            { label: 'rainbow', value: 'rainbow' },
            { label: 'teams', value: 'teams' },
            { label: 'virtual', value: 'virtual' },
          ],
          defaultValues: {},
          required: {
            text: '* Tipo de extensión',
            validation: true,
            regex: typeRegex.inputText,
            errorMessage: '* Agregar un tipo de extensión',
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Número  de desvío inmediato',
          icon: phone,
          placeholder: 'Número inmediato',
          ref: { form: 'extension', element: 'callForward' },
          defaultValues: null,
          required: {
            text: 'Número  de desvío inmediato',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Número  de desvío por no contestar',
          icon: phone,
          placeholder: 'Numero no contestar',
          ref: { form: 'extension', element: 'callForwardNA' },
          defaultValues: null,
          required: {
            text: 'Número  de desvío por no contestar',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 2,
          cardType: 'toggle',
          label: 'Buzón de voz',
          placeholder: 'Buzón de voz',
          ref: { form: 'extension', element: 'voicemailActive' },
          defaultValues: false,
          icon: speakerphone,
          // radioType: 'mono',
          // options: [
          //     { label: 'Activa', value: 1, id: 4 },
          //     { label: 'Desactiva', value: 0, id: 5 },
          // ],
          required: {
            text: 'Buzón de voz (activa o desactiva)',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
    {
      number: '3',
      title: 'Tiempo de ring, Número de identificación de salida, Sede, Departamento, Categoría',
      id: 3,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 3,
          cardType: 'input',
          label: 'Tiempo de ring',
          icon: alarm,
          placeholder: 'Tiempo de ring',
          ref: { form: 'extension', element: 'ringTimeout' },
          defaultValues: null,
          required: {
            text: 'Tiempo de ring',
            validation: true,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 3,
          cardType: 'select',
          label: 'Número de identificación de salida',
          icon: phoneOutgoing,
          placeholder: 'Identificación de salida',
          ref: { form: 'extension', element: 'identification' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Número de identificación de salida',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 3,
          cardType: 'select-option',
          label: 'Sede',
          icon: stethoscope,
          placeholder: 'Sede',

          ref: { form: 'extension', element: 'companyId', type: 'dynamic' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Sede obligatoria',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 3,
          cardType: 'select-option',
          label: 'Departamento',
          placeholder: 'Departamento',
          icon: stethoscope,
          ref: { form: 'extension', element: 'department', type: 'associatedData' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Departamento',
            validation: true,
            errorMessage: '* Departamento obligatorio',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 3,
          cardType: 'select-option',
          label: 'Categoría',
          placeholder: 'Categoría',
          icon: stethoscope,
          ref: { form: 'extension', element: 'categories' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Categoría',
            validation: true,
            errorMessage: '* Categoría obligatoria',
            regex: typeRegex.inputText,
          },
        },
        // {
        //     layoutId: 3,
        //     cardType: 'toggle',
        //     label: 'Buzón de voz',
        //     placeholder: 'Buzón de voz',
        //     ref: { form: 'extension', element: 'voicemailActive' },
        //     defaultValues: false,
        //     icon: speakerphone,
        //     // radioType: 'mono',
        //     // options: [
        //     //     { label: 'Activa', value: 1, id: 4 },
        //     //     { label: 'Desactiva', value: 0, id: 5 },
        //     // ],
        //     required: {
        //         text: 'Buzón de voz (activa o desactiva)',
        //         validation: false,
        //         errorMessage: '',
        //         regex: /^/,
        //     },
        // },
        {
          layoutId: 3,
          cardType: 'multiselect',
          icon: textInput,
          label: 'Allow',
          placeholder: 'Allow',
          ref: { form: 'extension', element: 'allow' },
          defaultValues: [],
          options: [
            { label: 'Alaw', value: 'alaw', id: 1 },
            { label: 'Ulaw', value: 'ulaw', id: 2 },
            { label: 'g729', value: 'g729', id: 3 },
          ],
          required: {
            text: 'Allow',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
    {
      number: '4',
      title: 'Grabaciones, Llamada en espera',
      id: 4,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 4,
          cardType: 'radio',
          label: 'Grabaciones',
          placeholder: 'Grabaciones',
          icon: volume,
          ref: { form: 'extension', element: 'record' },
          defaultValues: '',
          radioType: 'multi',
          options: [
            { label: 'Internas', value: 'internas', id: 1 },
            { label: 'Salientes', value: 'salientes', id: 2 },
            { label: 'Externas', value: 'externas', id: 3 },
          ],
          required: {
            text: 'Grabaciones (selecciona una o más opciones)',
            validation: false,
            errorMessage: 'Selecciona una opción',
            regex: /^/,
          },
        },
        // {
        //     layoutId: 4,
        //     cardType: 'multiselect',
        //     label: 'Grupos de captura',
        //     icon: textInput,
        //     placeholder: 'Grupos de captura',
        //     ref: { form: 'extension', element: 'pikedGroup' },
        //     defaultValues: '',
        //     options: [],
        //     required: {
        //         validation: false,
        //         errorMessage: 'Seleciona un grupo',
        //         regex: /^/,
        //     },
        // },
        // {
        //     layoutId: 4,
        //     cardType: 'toggle',
        //     label: 'Buzón de voz',
        //     placeholder: 'Buzón de voz',
        //     ref: { form: 'extension', element: 'voicemailActive' },
        //     defaultValues: false,
        //     icon: speakerphone,
        //     // radioType: 'mono',
        //     // options: [
        //     //     { label: 'Activa', value: 1, id: 4 },
        //     //     { label: 'Desactiva', value: 0, id: 5 },
        //     // ],
        //     required: {
        //         text: 'Buzón de voz (activa o desactiva)',
        //         validation: false,
        //         errorMessage: '',
        //         regex: /^/,
        //     },
        // },
        {
          layoutId: 4,
          cardType: 'toggle',
          label: 'Llamada en espera',
          placeholder: 'Llamada en espera',
          ref: { form: 'extension', element: 'callWaiting' },
          defaultValues: false,
          icon: speakerphone,
          // radioType: 'mono',
          // options: [
          //     { label: 'Activa', value: 1, id: 6 },
          //     { label: 'Desactiva', value: 0, id: 7 },
          // ],
          required: {
            text: 'LLamada en espera (activa o desactiva)',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
    {
      number: '5',
      title: 'Grupos de captura',
      id: 5,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 5,
          cardType: 'multiselect',
          label: 'Grupos de captura',
          icon: textInput,
          placeholder: 'Grupos de captura',
          ref: { form: 'extension', element: 'pikedGroup', type: 'associatedData' },
          defaultValues: '',

          options: [],
          required: {
            text: 'Grupos de captura',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
      ],
    },
  ],
};

export const dataScreenExtensions = {
  icon: {
    phoneCard,
    home,
    hierarchy,
  },
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna extensión creada',
    subtitle: 'Empieza por configurar una nueva extensión para visualizar en tu escritorio',
    textButton: 'Crear extensión',
  },
};

export const dataScreenListNumbering = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna lista de numeración creada',
    subtitle: 'Empieza por configurar una nueva lista de numeración para visualizar en tu escritorio',
    textButton: 'Crear lista de numeración',
  },
  icon: {
    phoneCard,
    home,
    hierarchy,
  },
};

export const dataFilterExtension = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Departamento',
      type: 'select',
      ref: 'department',
      options: [],
    },
    {
      label: 'Identificación',
      type: 'select',
      ref: 'identification',
      options: [],
    },
    {
      label: 'Tipo de extensión',
      type: 'select',
      ref: 'typeExtension',
      options: [],
    },
  ],
};
